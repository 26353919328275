import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constant } from '@core/constants';
import { UserService, CommonService } from '@core/services';
import { ICheque, IChequePayload, IMeta } from '@core/models';

@Injectable({
    providedIn: 'root',
})
export class ChequeService {
    public baseURL: string = '';
    public chequeURL: string = '';

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private commonService: CommonService,
        private userService: UserService
    ) {
        this.baseURL = this.commonService.getBaseURL('v1', 'transaction');
        this.chequeURL = this.commonService.getBaseURL('v1', 'cheque');
    }

    getHttpOptions() {
        const token: string = this.commonService.getToken();
        const orgId: string = this.userService.organizationId;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                orgid: orgId,
            }),
        };
        return httpOptions;
    }

    CreateCheque(payload: IChequePayload): Observable<{
        data: { id: string; file: string; preview: string };
        status: boolean;
        message: string;
    }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_CHEQUES('');
        return this.http.post<{
            data: { id: string; file: string; preview: string };
            status: boolean;
            message: string;
        }>(this.baseURL + url, payload, httpOptions);
    }

    updateTransaction(
        id: string,
        payload: IChequePayload
    ): Observable<{
        data: { id: string; file: string; preview: string };
        status: boolean;
        message: string;
    }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_TRANSACTION_BY_ID(id);
        return this.http.patch<{
            data: { id: string; file: string; preview: string };
            status: boolean;
            message: string;
        }>(this.baseURL + url, payload, httpOptions);
    }

    getCheques(qString: string): Observable<{ content: ICheque[]; meta: IMeta }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_CHEQUES(qString);
        return this.http.get<{ content: ICheque[]; meta: IMeta }>(this.baseURL + url, httpOptions);
    }

    getChequePDF = (id: string): Observable<{ status: boolean; data: { url: string } }> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_CHEQUE_PDF(id);
        return this.http.get<{ status: boolean; data: { url: string } }>(this.chequeURL + url, httpOptions);
    };

    getChequeByID(id: string): Observable<ICheque> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_TRANSACTION_BY_ID(id);
        return this.http.get<ICheque>(this.baseURL + url, httpOptions);
    }

    getPaymentTypes(): Observable<{ id: string; name: string }[]> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_PAYMENT_TYPES();
        return this.http.get<{ id: string; name: string }[]>(this.baseURL + url, httpOptions);
    }

    pingTransaction = () => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.PING_TRANSACTION;
        return this.http.get(this.baseURL + url, httpOptions);
    };
}
